// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
    transition: transform 0.3s ease;
  }
  
  img:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }`, "",{"version":3,"sources":["webpack://./src/styles/TradeSetup.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;EACjC;;EAEA;IACE,sBAAsB,EAAE,yBAAyB;EACnD","sourcesContent":["img {\n    transition: transform 0.3s ease;\n  }\n  \n  img:hover {\n    transform: scale(1.05); /* Slight zoom on hover */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
