// src/config.js

const config = {
    development: {
      baseAPIURL: "http://127.0.0.1:5000/api/data",
      razorPayButtonID: "pl_PR3LPaRw1KzBXs",
    },
    production: {
        baseAPIURL: "https://api.915trade.com/api/data",
        razorPayButtonID: "pl_PT3Uz2N8xCFC1B",
    },
  };
  
  const environment = "production";
  
  export const baseAPIURL = config[environment].baseAPIURL;
  export const razorPayButtonID = config[environment].razorPayButtonID;
  
