import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="landing-page">
      {/* Header Section */}
      <header className="landing-header">
        <h1>
          Unmatched Derivatives Insights
        </h1>
        <p>
          Gain the ultimate edge in understanding market movements with insights you won’t find anywhere else. Exclusively for Nifty and Sensex derivatives.
        </p>
        <button onClick={() => handleNavigate('/payment')} className="subscribe-button">
          Subscribe Now
        </button>
      </header>

      {/* Sections */}
      <main className="landing-content">
        {/* Section 1 */}
        <section className="landing-section">
          <img
            src="/multiple_strikes.gif"
            alt="Strike Wise Activity"
            className="section-image"
          />
          <div className="section-text">
            <h2>Track Strike-Wise Seller Activity</h2>
            <p>
              We provide real-time insights into seller activity for both Calls and Puts at each strike. Understand why prices move the way they do with unparalleled precision.
            </p>
          </div>
        </section>

        {/* Section 2 */}
        <section className="landing-section">
          <div className="section-text">
            <h2>Identify Seller-Driven Price Movements</h2>
            <p>
              In Derivatives, it is widely accepted and understood that sellers move the markets. Track their behavior and know when they’re adding or exiting positions to predict market movements.
            </p>
          </div>
          <img
            src="/sellers.gif"
            alt="Seller Behavior"
            className="section-image"
          />
        </section>

        {/* Section 3 */}
        <section className="landing-section">
          <img
            src="/liquidity.svg"
            alt="Liquidity Tracking"
            className="section-image"
            style={{
              transform: "scale(0.75)", // Shrink image to 75%
              transformOrigin: "center", // Scale from the center
            }}
          />
          <div className="section-text">
            <h2>Analyze Liquidity Like Never Before</h2>
            <p>
              Volume alone can’t give you the complete picture. Our insights go beyond volume to provide actionable data on real-time market liquidity.
            </p>
          </div>
        </section>

        {/* Section 4 */}
        <section className="landing-section">
          <div className="section-text">
            <h2>Exclusive Focus on Derivatives</h2>
            <p>
              Our platform currently offers insights on derivatives contracts for traders in Nifty and Sensex etc. Focused, precise, and tailored to your needs.
            </p>
          </div>
          <img
            src="/derivative-focus.svg"
            alt="Nifty and Sensex"
            className="section-image"
            style={{
              transform: "scale(0.75)", // Shrink image to 75%
              transformOrigin: "center", // Scale from the center
            }}
          />
        </section>

        {/* Section 5 */}
        <section className="landing-section">
          <img
            src="/real-time-data.svg"
            alt="Real-Time Insights"
            className="section-image"
            style={{
              transform: "scale(0.75)", // Shrink image to 75%
              transformOrigin: "center", // Scale from the center
            }}
          />
          <div className="section-text">
            <h2>Real-Time Data at Your Fingertips</h2>
            <p>
              Our insights are updated in real-time, ensuring you always have the most accurate data to make informed decisions.
            </p>
          </div>
        </section>

        {/* Section 6 */}
        <section className="landing-section">
          <div className="section-text">
            <h2>Unparalleled Advantage</h2>
            <p>
              With our platform, you gain an unmatched edge in the market. Spot the trends before others and trade with confidence.
            </p>
          </div>
          <img
            src="/advantage.svg"
            alt="Unmatched Advantage"
            className="section-image"
            style={{
              transform: "scale(0.75)", // Shrink image to 75%
              transformOrigin: "center", // Scale from the center
            }}
          />
        </section>
      </main>
    </div>
  );
};

export default LandingPage;