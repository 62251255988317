import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import SellLines from "./components/pages/SellLines";
import NiftyHistory from "./components/pages/NiftyHistory";
import Registration from "./components/pages/Registration";
import Login from "./components/pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import { AuthProvider } from "./AuthContext";
import LandingPage from "./components/pages/LandingPage";
import Payment from "./components/pages/Payment";
import Header from "./Header";
import SubscriptionRoute from "./components/pages/SubscriptionRoute";
import TermsAndConditions from "./TermsAndConditions";
import Footer from "./Footer";
import TradeSetup from "./components/pages/TradeSetup";
import PrivacyPolicy from "./PrivacyPolicy";
import PaymentSuccess from "./components/pages/PaymentSuccess";
import NiftyHistoryBuy from "./components/pages/NiftyHistoryBuy";
import SellLinesBuy from "./components/pages/SellLinesBuy";
import NiftyIndexLive from "./components/pages/NiftyIndexLive";
import BankniftyIndexLive from "./components/pages/BankniftyIndexLive";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/trade-ideas" element={<TradeSetup />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>}/>
          <Route path="/payment-success" element={<ProtectedRoute><PaymentSuccess /></ProtectedRoute>}/>
          <Route path="/live-data" element={<ProtectedRoute><SubscriptionRoute><SellLines /></SubscriptionRoute></ProtectedRoute>}/>
          <Route path="/nifty-history" element={ <ProtectedRoute> <NiftyHistory /> </ProtectedRoute> } />
          <Route path="/nifty-history-buy" element={ <ProtectedRoute> <NiftyHistoryBuy /> </ProtectedRoute> } />
          <Route path="/live-data-buy" element={ <ProtectedRoute> <SellLinesBuy /> </ProtectedRoute> } />
          <Route path="/live-nifty-index" element={ <ProtectedRoute> <NiftyIndexLive /> </ProtectedRoute> } />
          <Route path="/live-banknifty-index" element={ <ProtectedRoute> <BankniftyIndexLive /> </ProtectedRoute> } />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default App;
