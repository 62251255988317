import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Autocomplete, Checkbox, TextField, Box, Grid, Typography } from "@mui/material";
import LineChartBuy from "../charts/LineChartBuy";
import CandleChart from "../charts/CandleChart";
import { baseAPIURL } from "../../Config";
import { useAuth } from "../../AuthContext";

const SellLinesBuy = () => {
  const { auth } = useAuth();
  const [strikes, setStrikes] = useState([]);
  const [selectedStrike, setSelectedStrike] = useState(null);
  const [selectedStrikes, setSelectedStrikes] = useState([]);
  const [sellData, setSellData] = useState(null);
  const [candleData, setCandleData] = useState(null);
  const [niftyPrice, setNiftyPrice] = useState(null);
  const [niftyATMStrike, setNiftyATMStrike] = useState(null);
  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);

  const isMarketOpen = () => {
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
    const marketOpenMinutes = 9 * 60 + 15; // 9:15 AM
    const marketCloseMinutes = 15 * 60 + 30; // 3:30 PM
    return (
      currentMinutes >= marketOpenMinutes && currentMinutes < marketCloseMinutes
    );
  };

  const clearTimers = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const fetchNiftyPrice = async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/get-nifty-live-price`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setNiftyPrice(response.data.price);
      setNiftyATMStrike(response.data.atmStrike);
    } catch (error) {
      console.error("Error fetching Nifty price:", error);
    }
  };

  const fetchData = async (strike) => {
    try {
      const [sellResponse, candleResponse] = await Promise.all([
        axios.post(
          `${baseAPIURL}/get-sell-data`,
          { strike },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        ),
        axios.post(
          `${baseAPIURL}/get-candle-data`,
          { strike },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        ),
      ]);

      setSellData(sellResponse.data);
      setCandleData(candleResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to fetch data. Please check the API.");
    }
  };

  const handleStrikeClick = (strike) => {
    setSelectedStrike(strike);
    fetchData(strike);

    clearTimers();

    if (isMarketOpen()) {
      const refreshData = async () => {
        if (isMarketOpen()) {
          await fetchData(strike);
          await fetchNiftyPrice();
        } else {
          clearTimers();
        }
      };

      const now = new Date();
      const delay = 60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

      timeoutRef.current = setTimeout(() => {
        refreshData();
        intervalRef.current = setInterval(refreshData, 60000);
      }, delay);
    } else {
      console.warn("Market is closed. Timer will not start.");
    }
  };

  useEffect(() => {
    const fetchStrikes = async () => {
      try {
        const response = await axios.get(`${baseAPIURL}/get-strikes`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setStrikes(response.data);
      } catch (error) {
        console.error("Error fetching strikes:", error);
        alert("Failed to fetch strikes. Please check the API.");
      }
    };

    fetchStrikes();
    fetchNiftyPrice(); // Fetch Nifty price on component load
    return clearTimers;
  }, [auth.token]);

  return (
    <div style={{ width: "100%", overflowX: "hidden", minHeight: "calc(100vh - 120px)", display: "flex", flexDirection: "column", fontFamily: "Roboto, sans-serif" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "20px", marginRight: "20px" }}>
        <h1>Nifty</h1>

        <Autocomplete
          multiple
          options={strikes}
          getOptionLabel={(option) => option.strike.toString()}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                checked={selected}
                color="primary"
                style={{ marginRight: 8 }}
              />
              {option.strike}
            </li>
          )}
          onChange={(event, newValue) => setSelectedStrikes(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Select Strikes" variant="outlined" />
          )}
          sx={{ flex: 1, minWidth: "800px" }}
        />

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            mt: 2,
            mb: 4,
          }}
        >
          {selectedStrikes.map((strike) => (
            <button
              key={strike.strike}
              onClick={() => handleStrikeClick(strike.strike)}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
                backgroundColor: selectedStrike === strike.strike ? "#1976d2" : "#e3f2fd",
                color: selectedStrike === strike.strike ? "#ffffff" : "#1976d2",
                border: selectedStrike === strike.strike ? "2px solid #008AED" : "1px solid #90caf9",
                borderRadius: "5px",
              }}
            >
              {strike.strike}
            </button>
          ))}
        </Box>
      </div>

      <div style={{ display: "flex", width: "100%", marginTop: "20px" }}>
        <div style={{ flex: "5", marginRight: "2%" }}>
          {sellData && <LineChartBuy data={sellData} />}
        </div>
        <div style={{ flex: "1.5", marginRight: "25px" }}>
          <Grid container spacing={2} sx={{ border: "1px solid #ccc", borderRadius: "5px", overflow: "hidden" }}>
            {/* Row 1: Nifty Current Price */}
            <Grid
              container
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #ccc", // Add border to separate rows
                padding: "10px 0",
              }}
            >
              <Grid item xs={6}>
                <Typography variant="h8" sx={{ fontWeight: "bold", color: "#1976d2" }}>
                  Nifty Current Price
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ color: "#555", fontWeight: "bold" }}>
                  {niftyPrice !== null ? `₹ ${new Intl.NumberFormat("en-IN").format(niftyPrice)}` : "Fetching..."}
                </Typography>
              </Grid>
            </Grid>
            {/* Row 2: At-The-Money Strike */}
            <Grid
              container
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #ccc", // Add border to separate rows
                padding: "10px 0",
              }}
            >
              <Grid item xs={6}>
                <Typography variant="h8" sx={{ fontWeight: "bold", color: "#1976d2" }}>
                  At-The-Money Strike
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ color: "#555", fontWeight: "bold" }}>
                  {niftyATMStrike !== null ? `${niftyATMStrike}` : "Calculating..."}
                </Typography>
              </Grid>
            </Grid>
            {/* Placeholder for additional rows */}
          </Grid>
        </div>
      </div>

      {candleData && (
        <div style={{ display: "flex", width: "100%", marginTop: "20px" }}>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <CandleChart data={candleData.CE} title="Call Candles" />
          </div>
          <div style={{ flex: 1 }}>
            <CandleChart data={candleData.PE} title="Put Candles" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SellLinesBuy;