import React from 'react';
import ReactECharts from 'echarts-for-react';

const LineChart = ({ data }) => {
  const getChartOptions = () => {
    return {
      title: {
        text: '',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        right: "7%",
        bottom: '13%', // Add extra padding at the bottom for the slider
        left: '5%',
      },
      xAxis: {
        type: 'category',
        data: data.CE.map((item) => item.candleTime),
        axisLabel: {
          rotate: 45,
          formatter: (value) => {
            const date = new Date(value);
            return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
          },
        },
      },
      yAxis: {
        type: 'value',
        name: 'Volume',
        axisLabel: {
          formatter: (value) => {
            if (value >= 1_000_000) {
              const millions = value / 1_000_000;
              return millions % 1 === 0 ? `${millions}M` : `${millions.toFixed(1)}M`;
            } else if (value >= 1_000) {
              return `${Math.floor(value / 1_000)}K`;
            }
            return value;
          },
        },
        min: Math.min(...data.CE.map((item) => item.sell_volume), ...data.PE.map((item) => item.sell_volume)) * 0.95,
        max: Math.max(...data.CE.map((item) => item.sell_volume), ...data.PE.map((item) => item.sell_volume)) * 1.05,
      },
      legend: {
        orient: 'vertical',
        right: 10,
        top: 'center',
        data: ['Call', 'Put'],
      },
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          bottom: 10, // Adjust slider position
        },
        {
          type: 'inside',
        },
      ],
      toolbox: {
        show: false,
        feature: {
          dataZoom: {
            title: {
              zoom: 'Zoom In',
              back: 'Reset Zoom',
            },
            yAxisIndex: false,
          },
        },
      },
      series: [
        {
          name: 'Call',
          type: 'line',
          data: data.CE.map((item) => Math.floor(item.sell_volume)),
          smooth: true,
          lineStyle: {
            color: 'red',
          },
          itemStyle: {
            color: 'red',
          },
        },
        {
          name: 'Put',
          type: 'line',
          data: data.PE.map((item) => Math.floor(item.sell_volume)),
          smooth: true,
          lineStyle: {
            color: 'blue',
          },
          itemStyle: {
            color: 'blue',
          },
        },
      ],
    };
  };

  return <ReactECharts option={getChartOptions()} style={{ height: '600px', width: '100%' }} />;
};

export default LineChart;