import React, {useEffect, useState} from "react";
import { Box, Typography, List, ListItem, ListItemText, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import { razorPayButtonID } from "../../Config"
import "../../styles/Payment.css";

const faqs = [
  {
    question: "What is included in the subscription?",
    answer: "You will receive 1 month of access to live Nifty analysis charts, covering over 50 strikes that we actively track for weekly expiries.",
  },
  {
    question: "Can I cancel my subscription? Is there a refund policy?",
    answer: "The subscription is a non-refundable, non-cancellable, and non-transferable payment. Once purchased, payments cannot be refunded under any circumstances.",
  },
  {
    question: "Can I upgrade to a yearly subscription?",
    answer: "We are working on introducing additional subscription options, including yearly plans. Stay tuned for updates.",
  },
  {
    question: "How do I access my insights after payment?",
    answer: "Once your payment is confirmed and your subscription is activated, the live-charts link will become accessible on your dashboard.",
  },
  {
    question: "Will my subscription be activated immediately after payment?",
    answer: "Subscriptions are activated at the end of the day, with the next trading day counted as the start date. For example, if you subscribe on January 5th, your subscription will begin on January 6th and end on February 5th."
  },
  {
    question: "How can I renew my subscription?",
    answer: "If you make a payment before your current subscription expires, the additional month will be added after your current subscription’s end date, ensuring uninterrupted access."
  },
];

const Payment = () => {
  const [isAgreed, setIsAgreed] = useState(false);
    useEffect(() => {
        const rzpPaymentForm = document.getElementById("rzp_payment_form");
        
        if (!rzpPaymentForm.hasChildNodes() & isAgreed) {
          const script = document.createElement("script");
          script.src = "https://checkout.razorpay.com/v1/payment-button.js";
          script.async = true;
          script.dataset.payment_button_id = razorPayButtonID;
          rzpPaymentForm.appendChild(script);
        }
    
      }, [isAgreed]);

  return (
    <Box>
      {/* Payment Section */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "70vh", px: 3 }}
      >
        <Grid item xs={12} md={5} sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src="/payment.svg"
            alt="Payment Illustration"
            style={{ width: "80%", borderRadius: "10px" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#1976d2",
              fontWeight: "bold",
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            Make Payment
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              1 Month Subscription for Nifty Insights
            </Typography>
            <Typography variant="h4" sx={{ color: "#1976d2", mt: 1 }}>
                <span role="img" aria-label="rupee-icon">₹</span> 9999
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Please click on the following button to complete your payment.
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={isAgreed}
                onChange={(e) => {
                  if (!isAgreed) {
                    setIsAgreed(true); // Allow check
                  }
                  // Do nothing if already checked
                }}
                name="termsCheckbox"
                color="primary"
              />
            }
            label={
              <Typography variant="body2" sx={{ color: "#555" }}>
                I agree to the{" "}
                <a
                href="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#1976d2",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Terms and Conditions
              </a>
              </Typography>
            }
            sx={{ mb: 3 }}
          />
          <form id="rzp_payment_form"></form>
        </Grid>
      </Grid>

      {/* FAQs Section */}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#f5f5f5",
          py: 4,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: "#1976d2",
            fontWeight: "bold",
            fontFamily: "'Roboto', sans-serif",
          }}
        >
          Frequently Asked Questions
        </Typography>
        <List sx={{ maxWidth: 800, margin: "0 auto" }}>
          {faqs.map((faq, index) => (
            <ListItem key={index} sx={{ mb: 2 }}>
              <ListItemText
                primary={faq.question}
                secondary={faq.answer}
                primaryTypographyProps={{
                  fontWeight: "bold",
                  color: "#333",
                }}
                secondaryTypographyProps={{
                  color: "#555",
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Payment;
