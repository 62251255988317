import React from "react";
import { Box, Typography, useMediaQuery, Divider } from "@mui/material";
import "../../styles/TradeSetup.css";

const TradeSetup = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const sections = [
    {
      gif: "/put_dominance.gif",
      title: "Spot One-Sided Market Momentum",
      description:
        "Identify when market momentum is dominated by one side—either Calls or Puts—with aggressive position additions, while the other side shows weakness or exits. Leverage these imbalances to take strategic positions on the weaker side and capitalize on potential market shifts.",
      description1:
        "Notice: Puts were extremely strong, selling significantly more than Calls, presenting Buy opportunities in Calls and Sell opportunities in Puts.",
    },
    {
      gif: "/crossover.gif",
      title: "Spot Key Crossover Points",
      description:
        "Look for instances where the day starts with one side—Calls or Puts—being dominant, but then the other side gains momentum and crosses over. These crossovers are powerful signals that the market dynamics are shifting, providing opportunities to enter buy positions for the initially dominant side.",
      description1: "1. At about 10:17, Puts became stronger, presenting a Buy opportunity for Calls or Sell opportunity for Puts. 2. Again at 12:52, Puts weakened, and Calls grew stronger, presenting a Buy opportunity for Puts and Sell opportunity for Calls.",
    },
    {
      gif: "/volume_stagnation.gif",
      title: "Identify Volume Stagnation to Anticipate Reversals",
      description:
        "Spot when the dominant side (Calls or Puts) hits a volume peak and fails to break through, staying stagnant for an extended period. This often signals that sellers on that side are losing interest and may start exiting their positions. Use this as a key indicator to take strategic positions by Buying on the same side or Selling on the opposite side to capitalize on the market shift.",
      description1: "See how Calls were stuck below 1M volume for a long time, eventually exiting too many positions. Presenting Buy opportunity on Call side and Sell opportunity on Put side.",
    },
    {
      gif: "/sudden_exits.gif",
      title: "Spot Sudden Exits",
      description:
        "Watch for moments when Puts or Calls exit large volumes of positions suddenly. This is a clear indicator of the losing side, signaling that prices of the exiting side will rise while the opposing side’s prices will fall. Leverage these sudden movements to take calculated positions and capitalize on the shift.",
      description1: "As we can notice, Puts exited close to 1.3M positions within 2 minutes, making it clear as to which direction the price would move. This presented a Buy opportunity for Put and Sell opportunity for Call.",
    }
  ];

  return (
    <Box sx={{ fontFamily: "Roboto, sans-serif", px: 2, py: 4 }}>
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile
                ? "column"
                : index % 2 === 0
                ? "row"
                : "row-reverse",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 4,
              mb: 6,
            }}
          >
            <Box sx={{ flex: 1, textAlign: isMobile ? "center" : "left" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#1976d2", mb: 2 }}
              >
                {section.title}
              </Typography>
              <Typography variant="body1" sx={{ color: "#555" }}>
                {section.description}
              </Typography>
              {section.description1 && (
                <Box
                  sx={{
                    borderLeft: "4px solid #1976d2",
                    backgroundColor: "#f0f4ff",
                    padding: 2,
                    mt: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "#333", mb: 1 }}
                  >
                    Notice:
                  </Typography>
                  <Typography variant="body1" sx={{ color: "#555" }}>
                    {section.description1}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                flex: 1,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={section.gif}
                alt={section.title}
                style={{
                  maxWidth: "100%",
                  maxHeight: "800px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Box>
          </Box>
          {index < sections.length - 1 && (
            <Divider
              sx={{
                borderColor: "#1976d2",
                my: 4, // Increase gap between sections
                width: "80%",
                mx: "auto",
                borderWidth: "1px",
              }}
            />
          )}
        </React.Fragment>
      ))}
      {/* Note Section */}
      <Box
        sx={{
          borderTop: "4px solid #1976d2",
          backgroundColor: "#f5f5f5",
          padding: 4,
          textAlign: "center",
          mt: 6,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: "#1976d2",
            mb: 2,
          }}
        >
          Note:
        </Typography>
        <Typography variant="body1" sx={{ color: "#555" }}>
          Users must consider all the above as software patterns and observations. None of these are recommendations or financial advice. We are not an advisory firm nor do we provide advisory services. Please refer to the{" "}
          <a
            href="/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#1976d2", textDecoration: "none", fontWeight: "bold" }}
          >
            Terms and Conditions
          </a>{" "}
          for further details.
        </Typography>
      </Box>
    </Box>
  );
};

export default TradeSetup;