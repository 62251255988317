// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  .payment-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
  }
  
  .payment-section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
  }
  
  .faqs {
    text-align: center;
    padding: 20px;
    background-color: #f5f5f5;
  }`, "",{"version":3,"sources":["webpack://./src/styles/Payment.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,SAAS;IACT,UAAU;IACV,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,YAAY;EACd;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,yBAAyB;EAC3B","sourcesContent":["body {\n    font-family: Roboto, sans-serif;\n    margin: 0;\n    padding: 0;\n    background-color: #f5f5f5;\n  }\n  \n  img {\n    max-width: 100%;\n    height: auto;\n  }\n  \n  .payment-header {\n    display: flex;\n    justify-content: flex-end;\n    padding: 10px 20px;\n  }\n  \n  .payment-section {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 70vh;\n  }\n  \n  .faqs {\n    text-align: center;\n    padding: 20px;\n    background-color: #f5f5f5;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
