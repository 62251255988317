import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { baseAPIURL } from "../../Config";
import axios from 'axios';
import { useAuth } from "../../AuthContext";
import "../../styles/PaymentSuccess.css";

const PaymentSuccess = () => {
    const { auth } = useAuth();
    const [searchParams] = useSearchParams();
    const [statusMessage, setStatusMessage] = useState('Loading...');
    const [showPaymentLink, setShowPaymentLink] = useState(false);
    const [loading, setLoading] = useState(true);
    const paymentId = searchParams.get('payment_id');
  
    useEffect(() => {
        const paymentDetails = window.Razorpay?.payment;
        console.log("NAGIN DANCE.......")
        console.log(paymentDetails)
      const verifyPayment = async () => {
        if (!paymentId) {
          setStatusMessage('Payment did not succeed. Please retry the payment.');
          setLoading(false);
          setShowPaymentLink(true);
          return;
        }
  
        try {
          const response = await axios.post(`${baseAPIURL}/verify-payment`,
              {
                payment_id: paymentId,
              },
              {
                headers: {
                  Authorization: `Bearer ${auth.token}`,
                },
              }
          );
  
          if (response.data.status === 'success') {
            setStatusMessage(
              'We received the payment notification.\n\n This will be verified and your access to Live Trades will be activated within 24 hours.\n\n You can view the historical insights in the meanwhile. \n\nThank you.'
            );
          } else {
            setStatusMessage('Payment did not succeed. Please retry the payment.');
          }
        } catch (error) {
          console.error('Error verifying payment:', error);
          setStatusMessage('An error occurred. Please retry the payment.');
        } finally {
          setLoading(false);
        }
      };
  
      verifyPayment();
    }, [paymentId, auth.token]);
  
    return (
      <div className="payment-success-container">
        {loading ? <h1>Loading...</h1> : <h1>{statusMessage}</h1>}
        {showPaymentLink ? (
            <div className="payment-link-container">
                <a href='/payment' className="payment-link">Go Back to Payment</a>
            </div>
            ) : null
        }
      </div>
    );
  };
  
export default PaymentSuccess;