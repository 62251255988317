import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import LineChart from "../charts/LineChart";
import CandleChart from "../charts/CandleChart";
import { baseAPIURL } from "../../Config";
import { useAuth } from "../../AuthContext";

const BankniftyIndexLive = () => {
  const { auth } = useAuth();
  const [lineData, setLineData] = useState(null);
  const [candleData, setCandleData] = useState(null);
  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);

  const isMarketOpen = () => {
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
    const marketOpenMinutes = 9 * 60 + 15; // 9:15 AM
    const marketCloseMinutes = 15 * 60 + 30; // 3:30 PM
    return (
      currentMinutes >= marketOpenMinutes && currentMinutes < marketCloseMinutes
    );
  };

  const clearTimers = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const fetchData = async () => {
    try {
      // Fetch Line Chart Data
      const lineResponse = await axios.get(`${baseAPIURL}/get-banknifty-index-lines`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      // Fetch Candle Chart Data
      const candleResponse = await axios.get(`${baseAPIURL}/get-banknifty-index-candles`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      // Update State with Data
      setLineData(lineResponse.data);
      setCandleData(candleResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to fetch data. Please check the API.");
    }
  };

  useEffect(() => {
    const fetchInitialDataAndStartTimer = async () => {
      await fetchData();

      if (isMarketOpen()) {
        const refreshData = async () => {
          if (isMarketOpen()) {
            await fetchData();
          } else {
            clearTimers();
          }
        };

        // Calculate delay to start the refresh at the 1st second of the next minute
        const now = new Date();
        const delay = 60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

        timeoutRef.current = setTimeout(() => {
          refreshData();
          intervalRef.current = setInterval(refreshData, 60000); // Refresh every minute
        }, delay);
      } else {
        console.warn("Market is closed. Timer will not start.");
      }
    };

    fetchInitialDataAndStartTimer();

    return clearTimers; // Cleanup on component unmount
  }, [auth.token]);

  return (
    <div style={{ width: "100%", overflowX: "hidden", minHeight: "calc(100vh - 120px)", display: "flex", flexDirection: "column", fontFamily: "Roboto, sans-serif" }}>
      <h1 style={{ marginLeft: "20px", marginTop: "20px" }}>Banknifty Index Live</h1>

      <div style={{ display: "flex", width: "100%", marginTop: "20px" }}>
        {/* Line Chart */}
        <div style={{ flex: "5", marginRight: "2%" }}>
          {lineData && <LineChart data={lineData} />}
        </div>
      </div>

      {/* Candle Chart */}
      {candleData && (
        <div style={{ display: "flex", width: "100%", marginTop: "20px" }}>
          <CandleChart data={candleData} />
        </div>
      )}
    </div>
  );
};

export default BankniftyIndexLive;
