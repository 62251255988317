import React, { useState, useEffect } from "react";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";
import LineChart from "../charts/LineChart";
import CandleChart from "../charts/CandleChart";
import { baseAPIURL } from "../../Config";
import { useAuth } from "../../AuthContext";

const NiftyHistory = () => {
  const { auth } = useAuth();
  const [expiries, setExpiries] = useState([]);
  const [tradeDates, setTradeDates] = useState([]);
  const [strikes, setStrikes] = useState([]);
  const [selectedExpiry, setSelectedExpiry] = useState(null);
  const [selectedTradeDate, setSelectedTradeDate] = useState(null);
  const [selectedStrike, setSelectedStrike] = useState(null);
  const [sellData, setSellData] = useState(null);
  const [candleData, setCandleData] = useState(null);

  useEffect(() => {
    const fetchExpiries = async () => {
      try {
        const response = await axios.get(`${baseAPIURL}/get-expiries`, {
          headers: {
            Authorization: `Bearer ${auth.token}`, // Include Bearer token
          },
        });
        const expiryOptions = response.data.map((item) => item.expiry); // Extract expiry values
        setExpiries(expiryOptions);
      } catch (error) {
        console.error("Error fetching expiries:", error);
      }
    };

    fetchExpiries();
  }, [auth.token]);

  useEffect(() => {
    if (!selectedExpiry) {
      setTradeDates([]); // Reset trade dates
      setSelectedTradeDate(null); // Reset selected trade date
      setSellData(null); // Clear charts
      setCandleData(null);
      return;
    }

    const fetchTradeDates = async () => {
      try {
        const response = await axios.post(`${baseAPIURL}/get-trade-dates`, {
          expiry: selectedExpiry,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        const tradeDateOptions = response.data.map((item) => item.trade_date); // Adjust if response structure differs
        setTradeDates(tradeDateOptions);
        setSelectedTradeDate(null); // Reset trade date selection
        setSellData(null); // Clear charts
        setCandleData(null);
        setStrikes([]);
      } catch (error) {
        console.error("Error fetching trade dates:", error);
      }
    };

    fetchTradeDates();
  }, [selectedExpiry, auth.token]);

  useEffect(() => {
    if (!selectedTradeDate) {
      setStrikes([]); // Reset strikes
      setSelectedStrike(null); // Reset selected strike
      setSellData(null); // Clear charts
      setCandleData(null);
      return;
    }

    const fetchStrikes = async () => {
      try {
        const response = await axios.post(`${baseAPIURL}/get-strikes`, {
          expiry: selectedExpiry,
          trade_date: selectedTradeDate,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setStrikes(response.data);
        setSelectedStrike(null); // Reset selected strike
        setSellData(null); // Clear charts
        setCandleData(null);
      } catch (error) {
        console.error("Error fetching strikes:", error);
      }
    };

    fetchStrikes();
  }, [selectedTradeDate, selectedExpiry, auth.token]);

  const fetchData = async (strike) => {
    try {
      const [sellResponse, candleResponse] = await Promise.all([
        axios.post(`${baseAPIURL}/get-history-sell-data`,
        {
          expiry: selectedExpiry,
          trade_date: selectedTradeDate,
          strike,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }),
        axios.post(`${baseAPIURL}/get-history-candle-data`, {
          expiry: selectedExpiry,
          trade_date: selectedTradeDate,
          strike,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }),
      ]);

      setSellData(sellResponse.data);
      setCandleData(candleResponse.data);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  const handleStrikeChange = (strike) => {
    setSelectedStrike(strike);
    fetchData(strike);
  };

  return (
    <div style={{ width: "100%", overflowX: "hidden", minHeight: "calc(100vh - 120px)", display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "20px", marginBottom: "20px", marginTop: "20px" }}>
        <h1 style={{ margin: 0 }}>Nifty History</h1>
        <Autocomplete
          options={expiries}
          value={selectedExpiry}
          onChange={(e, newValue) => setSelectedExpiry(newValue)}
          renderInput={(params) => <TextField {...params} label="Select Expiry" />}
          style={{ flex: 1 }}
          freeSolo={false}
          disableClearable
        />
        {selectedExpiry && (
          <Autocomplete
            options={tradeDates}
            value={selectedTradeDate}
            onChange={(e, newValue) => setSelectedTradeDate(newValue)}
            renderInput={(params) => <TextField {...params} label="Select Trade Date" />}
            style={{ flex: 1 }}
            freeSolo={false}
            disableClearable
          />
        )}
        {selectedTradeDate && (
          <Autocomplete
            options={strikes}
            value={selectedStrike}
            onChange={(e, newValue) => handleStrikeChange(newValue)}
            getOptionLabel={(option) => (typeof option === "object" ? option.strike.toString() : option.toString())}
            renderInput={(params) => <TextField {...params} label="Select Strike" />}
            style={{ flex: 1 }}
            freeSolo={false}
            disableClearable
          />
        )}
      </div>

      {sellData && (
        <div style={{ float: "left", width: "100%", marginRight: "5%" }}>
          <LineChart data={sellData} />
        </div>
      )}
      {candleData && (
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <CandleChart data={candleData.CE} title="Call Candles" />
          </div>
          <div style={{ flex: 1 }}>
            <CandleChart data={candleData.PE} title="Put Candles" />
          </div>
        </div>
      )}
    </div>
  );
};

export default NiftyHistory;