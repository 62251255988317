import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import '../src/styles/Footer.css';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#1976d2",
        color: "white",
        py: 4,
        mt: 4,
        textAlign: "center",
        fontFamily: "Roboto", // Set Roboto font globally for the footer
      }}
    >
      <Grid container justifyContent="center" spacing={2}>
        {/* Footer Sections */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li style={{ marginBottom: "10px" }}> {/* Added spacing */}
              <Link
                to="/"
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Home
              </Link>
            </li>
            <li style={{ marginBottom: "10px" }}> {/* Added spacing */}
              <Link
                to="/live-data"
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Live Charts
              </Link>
            </li>
            <li style={{ marginBottom: "10px" }}> {/* Added spacing */}
              <Link
                to="/nifty-history"
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Nifty History
              </Link>
            </li>
            <li style={{ marginBottom: "10px" }}> {/* Added spacing */}
              <Link
                to="/terms-and-conditions"
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Terms & Conditions
              </Link>
              <li style={{ marginTop: "10px" }}> {/* Added spacing */}
                <Link
                  to="/privacy-policy"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </Link>
            </li>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
            We provide unmatched insights for derivatives traders, helping you
            make informed decisions with real-time data and analytics. Built
            for traders who aim to stay ahead of the curve.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
            Email: support@915trade.com
            <br />
            Address: Evolve Work Studio, Yelenahalli, 4th Floor, The hub, @Raj Serenity, Bangalore - 560068
          </Typography>
        </Grid>
      </Grid>

      {/* Copyright Section */}
      <Box sx={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)", mt: 4, pt: 2 }}>
        <Typography variant="body2">
          © {new Date().getFullYear()} 915Trade. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;