import React, { useState } from "react";
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogout = () => {
    logout(); // Clear auth context and token
    navigate("/login"); // Redirect to login page
  };

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const menuItems = auth.token
    ? [
        { text: "Payment", onClick: () => navigate("/payment") },
        { text: "History Charts", onClick: () => navigate("/nifty-history") },
        { text: "Live Charts", onClick: () => navigate("/live-data") },
        { text: "Logout", onClick: handleLogout },
      ]
    : [
        { text: "Trade Ideas", onClick: () => navigate("/trade-ideas") },
        { text: "Register", onClick: () => navigate("/register") },
        { text: "Login", onClick: () => navigate("/login") },
      ];

  return (
    <>
      <AppBar position="static" sx={{ bgcolor: "#1976d2", padding: "0 16px" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Left: Logo */}
          <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => navigate("/")}>
            <img src="/915.gif" alt="Logo" style={{ height: "40px", marginRight: "10px" }} />
          </Box>

          {/* Right: Links for Desktop and Menu Icon for Mobile */}
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {menuItems.map((item, index) => (
              <Button
                key={index}
                onClick={item.onClick}
                sx={{
                  position: "relative", // Needed for the gradient underline
                  mx: 1,
                  textTransform: "none",
                  fontWeight: "bold",
                  color: "white",
                  "&:hover:after": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: "-4px", // Space between text and underline
                    height: "2px",
                    background: "linear-gradient(90deg, #fff, transparent)", // Gradient effect
                  },
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "block", md: "none" } }}
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile View */}
      <Drawer anchor="right" open={drawerOpen} onClose={() => toggleDrawer(false)}>
        <List sx={{ width: 250 }}>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => { 
                item.onClick(); 
                toggleDrawer(false); 
              }}>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Header;