import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import "../../styles/Registration.css";
import { baseAPIURL } from "../../Config";

const Registration = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");

  const validate = () => {
    const newErrors = {};
    const phoneRegex = /^[0-9]{10}$/; // Exactly 10 digits
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Valid email format
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/; // Minimum 8 characters, 1 digit, 1 special character

    // Full Name validation
    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required.";
    }

    // Phone validation
    if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone number must be exactly 10 digits.";
    }

    // Email validation
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }

    // Password validation
    if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        "Password must be at least 8 characters, with at least 1 digit and 1 special character.";
    }

    // Confirm password validation
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiError(""); // Clear previous API error message
    if (!validate()) {
      return;
    }

    try {
      const response = await fetch(`${baseAPIURL}/user-register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.status === "success") {
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      } else {
        setApiError(result.reason || "Unable to complete registration at the moment");
      }
    } catch (error) {
      console.error("Error registering user:", error);
      setApiError("Unable to complete registration at the moment");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Box>
      {/* Body */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "80vh", px: 3 }}
      >
        <Grid item xs={12} md={5} sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src="/registration.svg"
            alt="Infographic"
            style={{ width: "100%", borderRadius: "10px" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#1976d2",
              fontWeight: "bold",
              fontFamily: "'Roboto', sans-serif",
              textAlign: "center",
            }}
          >
            Register
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: "100%",
              maxWidth: 400,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <TextField
              label="Full Name"
              name="fullName"
              variant="outlined"
              value={formData.fullName}
              onChange={handleChange}
              required
              fullWidth
              error={!!errors.fullName}
              helperText={errors.fullName}
            />
            <TextField
              label="Phone Number"
              name="phone"
              variant="outlined"
              value={formData.phone}
              onChange={handleChange}
              required
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone}
            />
            <TextField
              label="Email ID"
              name="email"
              type="email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              required
              fullWidth
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              value={formData.password}
              onChange={handleChange}
              required
              fullWidth
              error={!!errors.password}
              helperText={errors.password}
            />
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              variant="outlined"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              fullWidth
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ bgcolor: "#1976d2", color: "#fff" }}
            >
              Register
            </Button>
            {apiError && (
              <Typography
                variant="body2"
                sx={{ color: "red", mt: 1, textAlign: "center" }}
              >
                {apiError}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Registration;